@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


* {
  box-sizing: border-box;
  font-family: "Prompt", sans-serif;
}

@layer utilities {
  .bg-postperty {
    background-color: #34bfd9;
    color: #fff;
  }

/* Remove Placeholder on focus this with className "input" together */
@layer components {
  .input:focus::placeholder {
    color: transparent;
  }
}

.gm-style .gm-style-iw-c{
  border:none;
  padding:0;
}

input:focus {
  outline: none;
}
/* Remove Margin infowindow */


  .text-postperty {
    color: #34bfd9;
  }
  .bg-invite-frined {
    background: #051532;
  }
  .bg-invite-frined-input {
    background: hsl(219, 40%, 20%);
  }
  .bg-dark-blue {
    background: hsl(219, 40%, 20%);
  }
  .bg-half-light {
    background: #fdfdfd;
  }
  .bg-half-light-2 {
    background: #eff0f4;
  }
  .bg-half-light-f5 {
    background: #f5f5f5;
  }
  .bg-blue-10{
    background-color: #D7F8FF;
  }

  .bg-important-green{
    background-color: #5cbf89;

  }

  .bg-quepal{
    background-color: #5cbf89;
    /* opacity: 95%; */
    /* background: linear-gradient(258.56deg, #134E5E 100%, #71B280 88.16%); */

  }

  .text-important-green {
    color: #5cbf89;
  }



  .bg-postperty-linear-light {
    background: linear-gradient(258.56deg, #189eea 30.48%, #00b8d2 88.16%);
  }

  .bg-main-green{
    background-color: #03C988;
  }

  .bg-main-yellow{
    background-color: #F4CE14;
  }
  .bg-sun{
    background: linear-gradient(258.56deg, #f8b500 100%, #fceabb 88.16%);
  }

  .border-property-linear-light {
    border-image: linear-gradient(258.56deg, #189eea 30.48%, #00b8d2 88.16%) 1;


  }


.zoomed {
    position: fixed;
    top: -10;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000; /* ensure it's on top of other content */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: black; /* or any other desired background color */
}

  /* Card Patter */
  .bg-f3f3fb {
    background-color: #F3F3FB;
  }
  /* Card Patter */
  
  .shadow-3d {
    box-shadow: 0px 2px 14px -10px #000;
    transition: all 0.3s ease;
  }
  .shadow-3d:hover {
    box-shadow: 0px 2px 28px -7px #000;
    transform: scale(1.05);
  }
  
  .bg-test {
    background: #fbfdfc;
  }

  .bg-white-wallpaper{
    background:#F2F5F9
  }

  .bg-half-black{
    background:#343737;
  }

  .bg-cream-t-black {
    background-color: #e1e2f3;
    color: 141414;
  }
  .bg-rainbow-r {
    background: linear-gradient(
      to right bottom,
      #4869f3,
      #6c6cf4,
      #8770f5,
      #9e74f6,
      #b278f6,
      #be7cf6,
      #c980f7,
      #d484f7,
      #da89f7,
      #e08ef8,
      #e693f8,
      #eb98f9
    );
  }
}

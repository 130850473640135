/* styles.css */
.active-underline {
    position: relative;
  }
  
  .active-underline::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: blue;
    transform: scaleX(1);
    transition: transform 0.3s ease-in-out;
  }
  
  .inactive-underline::after {
    transform: scaleX(0);
  }
  
/* .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  background: #fff;
  color: #000;
} */

.gm-ui-hover-effect {
    display: none !important;
}

map > div {
    border-radius: 10px;
}

.pgm-html-info-content-frame{
    border-radius: 10px 25px 25px 30px;
    opacity: 0.85;
}

.pgm-html-info-tail-frame{
    opacity: 0.45;
}
.control-info-marker {
    border:2px solid red;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* position: absolute; */
}

.control-info-marker img {
    width: 100%;
}

.control-info-marker button {
    border:2px solid blue;
    width: 100%;
    margin:1.5rem auto;
    height: 30px;
    border-radius: 100px;
}

.control-preview{
    border:2px solid green;
    display:grid;
    grid-template-columns: 2fr 3.5fr;
}

.control-preview:hover {
    display: flex;
    flex-direction: column;
}

.image-control {
    border:2px solid red;   
    margin:auto;
}

.information {
    padding:10px;
}